import Lenis from '@studio-freight/lenis'

const lenis = new Lenis({
	lerp: .1,
  easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
})
const offset = 100
const screensNumber = 5
var fullHeight = 0
var screensTP = []

function getDimensions(){
	var wh = window.innerHeight
	var fullHeight = wh * screensNumber
	var slides = document.getElementsByClassName("screen");
	for (var i = 0; i < slides.length; i++) {
	   screensTP.push(slides[i].offsetTop)
	}
}


function setStyle( objId, propertyObject ){
 var elem = document.getElementById(objId);
 for (var property in propertyObject)
    elem.style[property] = propertyObject[property];
}

function timeline(scrl){
	for (var i = 0; i < screensTP.length; i++) {
	   if(scrl.targetScroll >= screensTP[i] - offset){
	   	document.body.classList.add('s_' + i)
	   	infocus(scrl, i)
	   }else if(scrl.targetScroll <= screensTP[i] - offset){
	   	document.body.classList.remove('s_' + i)
	   	document.getElementById('s_' + (i+1)).classList.remove('exposed')
	   }
	}
}
function isInBetween(scrl, beg, end){
	return scrl.animatedScroll >= screensTP[beg] && scrl.animatedScroll <= screensTP[end] + (window.innerHeight * .65)
}
function infocus(scrl, i){
	var el = document.getElementById('s_' + (i+1))
   if(isInBetween(scrl, i, i)){
 		el.classList.add('exposed')
   }else{
		el.classList.remove('exposed')
   }
}
function fixHeading(scrl){
	if(isInBetween(scrl, 2, 3)){
		document.getElementById('s_3').children[0].classList.add('fixed')
	} else{
		document.getElementById('s_3').children[0].classList.remove('fixed')
	}
}
function animateStar(scrl){
	if(isInBetween(scrl, 1, 2)){
		var start = screensTP[0]
		var end = screensTP[1]
		var off = 20
		var percent = 100 / ((end - start) /  (end - scrl.animatedScroll))
		document.getElementById('star').style.transform = 'rotate('+(percent + off) +'deg)'
	}else if(scrl.animatedScroll < screensTP[1]){
		document.getElementById('star').style.transform = 'rotate(20deg)'
	}else{
		document.getElementById('star').style.transform = 'rotate(-120deg)'
	}
}
function animateMatter(scrl){
	var start = screensTP[1]
	var end = screensTP[4]
	var off = -45
	var percent = (100 / ((end - start) /  (end - scrl.animatedScroll)))*2
	var speed = window.innerWidth / 1500
	console.log(speed)
	if(isInBetween(scrl, 1, 4)){
		document.getElementById('matter').style.transform = 'rotate(' + ((percent + off) * -1) * speed + 'deg)'
	}	
	if(isInBetween(scrl, 2, 3)){
		var size = ((100 - percent) + 50) + 1.3 + 'vw'
		setStyle('matter', {'width':size , 'height':size});
	}
}
// --
getDimensions()

lenis.on('scroll', (e) => {
  timeline(e)
  fixHeading(e)
  animateStar(e)
  animateMatter(e)

  // verticalScroll(e)
})

function raf(time) {
  lenis.raf(time)
  requestAnimationFrame(raf)
}

requestAnimationFrame(raf)

console.log('made by 2046.cz')
console.log('credits: photo by Nathan Anderson')
// function verticalScroll(scrl){
// 	const scrollableContainer = document.querySelector('.scrollable-container');
	
// 	const scrollLeft = (scrl.animatedScroll - screensTP[3] );
// 	var left = scrollableContainer.getBoundingClientRect().top < 0 ? scrollableContainer.getBoundingClientRect().top * 1.9 : 0
// 	console.log(left)
// 	setStyle('p_1', {'transform': 'translateX(' + left + 'px)' });
// 	setStyle('p_2', {'transform': 'translateX(' + left + 'px)' });
// 	setStyle('p_3', {'transform': 'translateX(' + left + 'px)' });
// }

